.symptom-block {
  background-color: #fff;
  padding: 0.5rem;
  border: 1px solid #80DB2F;
  border-radius: 5px;
  color: #80DB2F;
  text-align: center;
  margin: 0 0.5rem 1rem 0;
  display: inline-block;
  cursor: pointer;
}