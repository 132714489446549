.dialog-line {
  box-shadow: 0px 2px 6px #D6D6D6;
  text-align: left;
  padding-top: 1em;
  max-width: 85%;
}

.single-dialog-container {
  margin: 0.5rem 1rem 0 0.15rem !important;
}

.dialog-question {
  margin: 0 0.75rem 0.5rem;
  border: 1px solid #d0d0d0;
  -webkit-border-radius: 35px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius: 35px;
  -moz-border-radius-topleft: 0;
  border-radius: 35px;
  border-top-left-radius: 0;
  padding: 0.75rem 0.75rem 0 0.75rem;
}

.dialog-answer {
  margin: 0 0.75rem 0.5rem auto;
  -webkit-border-radius: 35px;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 35px;
  -moz-border-radius-topright: 0;
  border-radius: 35px;
  border-top-right-radius: 0;
  padding: 0.75rem 0.75rem 0 0.75rem;
  text-align: right;
  background-color: #80DB2F;
  color: #fff;
}

.dialog-answers-container {
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: 1rem;
  margin: 0 !important;
  overflow: auto;
}