html, body, #root, .App, .container-100 {
  height: 100%;
}

.green-button {
  width: 100%;
  border: none !important;
  color: #FFFFFF;
  line-height: 1.2 !important;
  padding: 0.75rem;
  border-radius: 5px;
  white-space: normal;
  background-color: #80DB2F;
}

:focus {
  outline: none !important;
}