.logo-with-bubble {
  height: 40vh;
  width: 50%;
  margin-top: 1rem;
  background-image: url('../../assets/images/Group\ 22.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
}

.selected-symptoms-row {
  overflow-y: auto;
  max-height: 18vh;
  margin: 0.5rem 0;
}