body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root, .App, .container-100 {
  height: 100%;
}

.green-button {
  width: 100%;
  border: none !important;
  color: #FFFFFF;
  line-height: 1.2 !important;
  padding: 0.75rem;
  border-radius: 5px;
  white-space: normal;
  background-color: #80DB2F;
}

:focus {
  outline: none !important;
}
.symptom-block {
  background-color: #fff;
  padding: 0.5rem;
  border: 1px solid #80DB2F;
  border-radius: 5px;
  color: #80DB2F;
  text-align: center;
  margin: 0 0.5rem 1rem 0;
  display: inline-block;
  cursor: pointer;
}
.logo-with-bubble {
  height: 40vh;
  width: 50%;
  margin-top: 1rem;
  background-image: url("/static/media/Group 22.33aaed37.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
}

.selected-symptoms-row {
  overflow-y: auto;
  max-height: 18vh;
  margin: 0.5rem 0;
}
.dialog-line {
  box-shadow: 0px 2px 6px #D6D6D6;
  text-align: left;
  padding-top: 1em;
  max-width: 85%;
}

.single-dialog-container {
  margin: 0.5rem 1rem 0 0.15rem !important;
}

.dialog-question {
  margin: 0 0.75rem 0.5rem;
  border: 1px solid #d0d0d0;
  -webkit-border-radius: 35px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius: 35px;
  -moz-border-radius-topleft: 0;
  border-radius: 35px;
  border-top-left-radius: 0;
  padding: 0.75rem 0.75rem 0 0.75rem;
}

.dialog-answer {
  margin: 0 0.75rem 0.5rem auto;
  -webkit-border-radius: 35px;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 35px;
  -moz-border-radius-topright: 0;
  border-radius: 35px;
  border-top-right-radius: 0;
  padding: 0.75rem 0.75rem 0 0.75rem;
  text-align: right;
  background-color: #80DB2F;
  color: #fff;
}

.dialog-answers-container {
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: 1rem;
  margin: 0 !important;
  overflow: auto;
}
.time-block {
  padding: 0.5rem 1rem 0;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: #d0d0d0 0 3px 6px;
}

.article-block {
  padding: 0.5rem 1rem 0;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: #d0d0d0 0 3px 6px;
}

