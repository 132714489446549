.time-block {
  padding: 0.5rem 1rem 0;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: #d0d0d0 0 3px 6px;
}

.article-block {
  padding: 0.5rem 1rem 0;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: #d0d0d0 0 3px 6px;
}